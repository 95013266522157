<template>
  <b-modal
    id="delete-modal"
    centered
    :title="modalTitle"
    cancel-disabled
    ok-disabled
    @hide="() => handleCancel('forceCancel')"
  >
    <template #default>
      <span v-if="!order.order_status">
        Clicking <span style="font-weight: bold">CONFIRM</span> will delete the
        following order
      </span>
      <span v-if="order.order_status">
        <span style="font-weight: bold">IMPORTANT!</span> You have selected to
        DELETE an order that has already been processed at University of the
        Pacific, Benerd College. The request you are submitting should only be
        completed by a University Credit Administrator. Please review the
        information below prior to clicking CONFIRM
      </span>
      <ul style="margin-top: 10px">
        <li>UC Order ID: {{ order.order }}</li>
        <li>First Name: {{ order.first_name }}</li>
        <li>Last Name: {{ order.last_name }}</li>
        <li>Course Number: {{ order.course ? order.course.course_number : ""}}</li>
        <li>Course Title: {{ order.course ? order.course.course_title : "" }}</li>
        <li>Credits: {{ order.course_credits }}</li>
        <li>Course Credit Fee: ${{ order.course_credit_fee }}</li>
        <li>Course Fixed Credit Fee: ${{ order.course_fixed_credit_fee }}</li>
        <li>Total Course Fee: ${{ totalCreditFee }}</li>
      </ul>
    </template>
    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button
        variant="danger"
        @click="() => handleCancel(cancel)"
        :disabled="stage === 'processing'"
      >
        CANCEL
      </b-button>
      <b-button
        variant="success"
        @click="$emit('confirmDeleteOrder')"
        :disabled="stage === 'processing'"
        class="btn--with-loader"
      >
        {{ next ? "CONFIRM & NEXT" : "CONFIRM" }}
        <b-spinner
          style="width: 20px; height: 20px"
          v-if="stage === 'processing'"
          label="Loading..."
        ></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    modalMessage: {
      type: String,
      default: "",
    },
    modalTitle: {
      type: String,
      default: "Delete Order Confirmation",
    },
    next: {
      type: Object,
      default: null,
    },
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        document.getElementById("modal").style.display = "block";
      } else {
        document.getElementById("modal").style.display = "none";
      }
    },
    stage(newVal) {
      if (newVal === "success" || newVal === "error") {
        this.$emit("closeDialog");
        this.$root.$emit("bv::hide::modal", "delete-modal");
      }
    },
  },
  methods: {
    handleCancel(action) {
      this.$emit("next", { action, type: "deleteBulk" });
    },
  },
};
</script>


<style scoped>
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
</style>